.option__container{
    width: 400px;
    max-width: 100%;
    height: auto;
    background-color: #ffffff;
    font-size: 1.5rem;
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;

    color: rgb(75, 74, 74);
    /* margin: auto; */
    /* align-items: center; */
    text-align: center;
    box-shadow: 0px 4px 10px rgba(156, 156, 156, 0.1);
    border: 2px solid #ffffff;
    /* vertical-align: center; */
    line-height: 30px;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    


}
.option__container:hover{
    background-color:  #E8F5FF;

    border: 2px solid #4395F5;
    color: #4395F5;
    cursor: pointer;
}
.selected{
    background-color:  #E8F5FF;
    border: 2px solid #4395F5;
    color: #4395F5;
    cursor: pointer;
}
@media screen and (max-width: 992px) {
    .option__container{
        font-size: 1.3rem;
        height: 30px;
        line-height: 20px;
        width: 250px;
        padding: 20px;
       
    }
}