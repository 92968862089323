.analysis__container {
  height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #fcfcfc;
}
.analysis__logo {
  height: 50px;
  width: auto;
  background-image: url("../../assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.analysis__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #414549;
  margin-top: 2rem;
  font-size: 2.5rem;
}
.analysis__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.analysis_box {
  margin: 2rem 0px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    font-family: "Inter", sans-serif;
    color: #414549;
    font-weight: normal;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 1s ease-in-out;
  }
  #analysed div {
    color: #3bb4b6;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  #analysing {
    color: #4395f5;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  #analysing img {
    margin: 5px;
    animation: rotation 1s infinite linear;
  }

  #to_analysing div {
    font-size: 1.5rem;
    color: #b8b8b8;
    // color: rgb(137, 137, 137);
  }
  #to_analysing div div {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 900px) {
  .analysis_box {
    #analysed div {
      font-size: 1.2rem;
    }
    #analysing {
      font-size: 1.3rem;
    }
    #to_analysing div {
      font-size: 1.2rem;
    }
  }
}
