.testinomial__container {
  height: 100vh;
  padding: 10px 70px;
  box-sizing: border-box;
}
.testinomial__content {
  margin-top: 4rem;
  height: 50vh;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr;
  font-size: 2rem;
}

.content {
  height: 100%;
  overflow: hidden;
}
.content span {
  font-size: 1.5rem;
}
.testinomial__logo {
  height: 50px;
  width: 100%;
  background-image: url("../../assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.left {
  background-image: url("../../assets/testimonial.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 500px;
}
.testinomial__button {
  display: flex;
  height: 100px;
  grid-column-start: 1;
  grid-column-end: 3;

  align-items: center;
  justify-content: center;
}
.right {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.right p {
  color: #606060;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
}

.testinomial__button button {
  margin-top: 2rem;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 400px;
  height: 70px;
  border: none;
  background-color: #4395f5;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .testinomial__button button {
    width: 200px;
    height: 50px;
  }
  .testinomial__container {
    padding: 10px 30px;
  }

  .testinomial__content {
    margin-top: 0.5rem;
    font-size: 1rem;
    height: 10vh;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 2fr 1fr;
  }
}
@media only screen and (max-width: 1020px) {
  .left {
    height: auto;
  }
  .testinomial__content {
    font-size: 1.5rem;
    height: 80vh;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 2fr 1fr;
  }
  .testinomial__button {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
