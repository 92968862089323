.userInfoCard__container{
    
    width: 200px;
    height: 250px;
    display: grid;
    grid-template-rows: 3fr 1fr;
}
.userInfoCard__container:hover{
    .userInfoCard__content{
        color: #4395F5;
 
    }
}
.userInfoCard__heading{
    background: #ffffff;
    box-shadow: 2px 2px 60px 4px rgba(203, 203, 203, 0.2);
    border-radius: 20px;
    // background-image: url('../../assets/Student.svg');
    // background-position: center;
    // background-repeat: no-repeat;
    // width: 100%;
    // height: 100%;
    // background-size: cover;
   
}
.userInfoCard__content{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #414549;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin: auto;
}
.userInfoCard__heading:hover{
    cursor: pointer;
    // transform: scale(1.1);
   
 
    border: 3px solid #4395F5;
    color: #4395F5;
    
}
@media screen and (max-width: 600px) {
    .userInfoCard__container{
        width: 132px;
        height: 166px;
    }
    .userInfoCard__heading{
        border-radius: 13px;
    }
}