.newsletter__container {
  background-color: #fcfcfc;
  height: 100vh;
  padding: 2rem;
}

.newsletter__logo {
  height: 50px;
  width: 100%;
  background-image: url("../../assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.newsletter__content {
  margin-top: 5rem;
  height: 50vh;
}
.newsletter__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #414549;
  font-size: 2.5rem;
}
.newsletter__innerContent {
  align-items: center;
  width: fit-content;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #414549;
  font-size: 1.3rem;
  font-weight: 400;
  margin: auto;
  margin-top: 2rem;
}
.newsletter__input {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter__input input {
  width: 25rem;
  height: 4rem;
  background: #ffffff;

  border: 1px solid #afafaf;
  box-shadow: 2px 2px 60px 4px rgba(203, 203, 203, 0.1);
  border-radius: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  padding-left: 10px;
  line-height: 1.5rem;
  color: rgb(101 82 82);
}

.newsletter__button {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.newsletter__button button {
  font-size: 1.5rem;
  border-radius: 10px;
  width: 400px;
  height: 70px;
  border: none;
  background-color: #4395f5;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsletter__error{
  color: red;
  font-size: 1.5rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .newsletter__button button {
    width: 200px;
    height: 50px;
  }
  .newsletter__text {
    font-size: 1.8rem;
  }
}
