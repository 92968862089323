.testiCard{
    background-color: aqua;
    padding: 10px;

    font-family: "Inter", sans-serif;
    border-radius: 15px;
    background: #FFFFFF;
box-shadow: 2px 2px 60px rgba(95, 95, 95, 0.1);
    height: 200px;
    .testiUpper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        p{
            font-size: 18px;
            font-weight: 500;
            color: '#414549';
            margin: 20px 0px 0px 20px;
           
        }
    }
    .testiUpper img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 20px 0px 0px 20px;
    }
    .testiText{
        font-size: 18px;
        font-weight: 300;
        color: '#414549';
        margin: 0px 0px 0px 20px;

    }
}