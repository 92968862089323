.wrapper{
    background-color: #ffffff;
color:black;

display: flex;
justify-content: center;
width: 100%;
font-family: 'Raleway';
font-style: normal;

font-size: 1.2rem;
line-height: 2rem;
margin-bottom: 10px;
// background-color: transparent;

.collapsible{
    width: 100%;
    overflow: hidden;

    
    input{
        display: none;
    
    }
    label{
        box-sizing: border-box;
        position: relative;
        color: #414549;
        background:white;
        display: block;
        cursor: pointer;
        padding: 15px;
        border-radius: 18px;
        z-index: 1;
        font-size: 1.4rem;
        box-shadow: 2px 2px 60px 4px rgba(170, 170, 170, 0.2);
        font-weight: 400;
        font-family: "Inter", sans-serif;
        color: #414549;
        
      
        .arrow{
            position: absolute;
            right: 10px;
            top: 15px;
            background-image: url('../../assets/plus.svg');
            width: 30px;
            height: 30px;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.3s ease;
        }
    }
    .collapsible-text{
        width: 80%;
        max-height: 1px;
        overflow: hidden;
        border-radius: 18px;
 margin: auto;
        position: relative;
        top: -100%;
        opacity: 0.5;
        transition: all 0.3s ease;
    }
    & input:checked ~ .collapsible-text{
        max-height: 400px;
        width: 80%;
     margin: 10px auto;
        opacity: 1;
        top: 0;
        h2{
            margin-bottom: 10px;
            padding: 0 15px;
        }
        p{
            padding-left: 15px;
            padding-right: 15px;
        }
    
    
    }
    & input:checked ~ label .arrow{
        position: absolute;
        right: 15px;
        transition: none;
        top: 15px;
            background-image: url("../../assets/minus.svg");
        
    }
}
}