.line__container {
  display: flex;
  flex-direction: row;

  align-items: center;
  height: auto;
  justify-content: flex-start;
  height: 1.5rem;
}
.line__content {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  color: #433738;
}
.line__contentRecommended {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #433738;
}

.line__circle {
  background-color: #632fd2;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  img {
    width: 12px;
    height: 12px;
  }
}
