.NextCard__container{
  width: 100%;
 height: 180px;
 background-color: #fcfcfc;
/* background: #b03a3a; */
border-radius: 12px;
display: grid;
grid-template-rows: 1fr 2fr;
gap: 10px;
}
.NextCard__container:hover{
background-color: #ffffff;
box-shadow: 2px 2px 60px 4px rgba(203, 203, 203, 0.1);

}
.NextCard__title{
    font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 1.4rem;
font: bold;
padding: 20px;
padding-bottom: 10px;

color: #414549;
}
.NextCard__para{
    font-family: 'Raleway';
font-style: normal;

font-size: 1.1rem;

padding: 20px;
padding-top: 0px;


color: #696969;
}