.Footer__Container {


    margin-top: 40px;
    width: 100%;
  }
  
  .Footer__Content {
    display: flex;

  }
  
  ul {

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    width: 100%;
    // background-color: red;
  }
  
  li {
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 400;
  }
  
  a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #666;
  }
  