.paycard__container {
  width: 300.386px;
  height: auto;
  display: flex;
  flex-direction: column;

  // border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 60px rgba(161, 161, 161, 0.15);
  border-radius: 25px;
  padding: 2rem;
  padding-top: 1.6rem;
  position: relative;
  overflow: hidden;
  transition: opacity 0.5s ease;
}
// .paycard__tagContent {
//   transform: rotate(45deg);
// }
.paycard__tag {
  position: absolute;
  right: -20px;
  top: 23px;
  background: linear-gradient(91deg, #d14de7 7.84%, #632fd2 95.41%);
  width: 100px;
  height: 15px;
  transform: rotate(45deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    font-size: 0.8rem;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: bold;
  }
}
.paycard__planName {
  display: flex;

  flex-direction: row;
  margin-top: 0;
  padding: 0;
  // margin-left: 20px;
}
.paycard__planContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding: 0;
}
.paycard__planTimer {
  font-weight: normal;
  color: #939393;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 10px;
  padding: 0;
  margin-top: 0;
}
.paycard__planTitle1 {
  font-weight: bolder;
  font-size: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 10px;
  padding: 0;
  margin-top: 0;
}
.paycard__planTitle2 {
  margin-left: 10px;
  font-weight: normal;
  font-size: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.paycard__price {
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}
.paycard__button {
  // background: linear-gradient(91deg, #d14de7 7.84%, #632fd2 95.41%);
  border-radius: 25px;
  width: 20rem;
  margin: auto;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 30px;
  margin-bottom: 20px;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto;
  }
  span {
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 7px;
    margin-left: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto;

    text-decoration: line-through;
  }
}
.paycard__discount {
  font-size: 0.8rem;
}
.playcard__timer {
  display: inline;
  color: red;
}
@media screen and (max-width: 600px) {
  .paycard__container {
    width: 300px;
  }
  .paycard__button {
    width: 15rem;
  }
}
