.userInfo__container{
    // background-color: red;


    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 2rem;
}
.userInfo__cards{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 900px) {
    .userInfo__container{
        grid-template-columns: 1fr;
    }
    
}