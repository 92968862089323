.playstore_div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 100%;
  font-family: sans-serif;
  background: linear-gradient(45deg, #4396f523, #00a2ff19);
  border-radius: 20px;
  margin: 20px 0px;
  overflow: hidden;

  div {
    margin: 10px;

    .app_name {
      color: #4395f5;
      font-weight: normal;
      font-size: 50px;
    }
    .PlayDetails img {
      height: 60px;
      margin: 10px 0px;
    }
    .play_btn img {
      height: 50px;
    }
  }
  .video_body {
    position: relative;
    padding: 0;
    .circle {
      z-index: 0;
      width: 500px;
      height: 500px;
      background-color: rgba(67, 150, 245, 0.286);
      border-radius: 100%;
      position: absolute;
      bottom: -200px;
      left: 60%;
    }
    .youtube_video {
      border-radius: 10px;
      box-shadow: 0px 2px 10px #686868;
      position: relative;
      z-index: 5;
    }
  }
}

@media only screen and (max-width: 800px) {
  .playstore_div {
    grid-template-columns: 1fr;

    div {
      margin: 10px;

      .app_name {
        font-size: 30px;
      }
      .PlayDetails img {
        height: 40px;
        margin: 10px 0px;
      }
    }

    .video_body {
      .circle {
        width: 300px;
        height: 300px;
        bottom: -100px;
        left: 50%;
      }
      .youtube_video {
        border-radius: 10px;
        box-shadow: 0px 2px 10px #686868;
        position: relative;
        z-index: 5;
      }
    }
    
  }
}
