body {
  margin: 0px;
  padding: 0px;
}
.survey__container {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
.survey__main {
  width: 70%;
  height: 100vh;
  margin: auto;
  /* background-color: rgb(195, 247, 247); */
  display: flex;

  flex-direction: column;
}
.survey__progress {
  display: flex;
  flex-direction: column;

  height: 20%;
  /* background-color: bisque; */

  /* align-items: center; */
}
.probar {
  height: 3px;
  background-color: 'rgba(79, 81, 140, 1.0)'
}

.progressBar {
  border-radius: 15px;
  height: 5px;
  margin-top: 2px;
}
.survey__counter {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-top: 4px;
  text-align: right;
  margin-right: 5px;
}
.survey__heading {
  color: rgb(0, 0, 0);
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  margin: auto;
  font-weight: 800;

  /* font-size: larger; */
}
.survey__question {
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin-top: 1rem;
  text-align: center;
}
.survey__description {
  margin-top: 15px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 29px;
  text-align: center;
}
.survey__options {
  width: 100%;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-self: center;
}

.survey__button {
  /* align-items: center;s */
  /* font-size: 40px; */
  margin: auto;
  /* width: auto; */
  /* margin-bottom: 1rem; */
  margin-top: 3rem;
  display: none;
}

.survey__button button {
  font-size: 1.5rem;
  border-radius: 10px;
  width: 45vw;
  float: none;
  display: block;
  height: 70px;
  border: none;
  background-color: #4395f5;
  color: white;
  max-width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.choosen {
  display: inline-block;
}
@media screen and (max-width: 420px) {
  .survey__question {
    font-size: 1.5rem;
  }

  /* .option__container:nth-of-type(5) {
    grid-column: 1/2;
  } */
  .survey__options {
    grid-template-columns: 1fr;
    width: 330px;

    margin-left: 10px;
  }
  .survey__description {
    font-size: 1.3rem;
  }
  .survey__button {
    /* margin-top: 1rem; */
    margin-top: 0.5rem;
  }
  .survey__button button {
    font-size: 1.3rem;
    width: 200px;
    height: 50px;
  }
  .survey__heading img {
    width: 200px;
    height: 50px;
  }
  .scale__left{
    font-size: 10px;
  }
  .scale__right{
    font-size: 10px;
  }
  .survey__main{
    width: 90%;
  }

}
