.hero__container {
  background-color: #fcfcfc;
  overflow: hidden;
  padding: 2rem;
}
.hero__main {
  padding-left: 2rem;
  padding-right: 2rem;
}

.hero__logo {
  height: 50px;
  width: 100%;
  background-image: url("../../assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__content {
  margin-top: 5rem;
  /* height: 50vh; */
  display: grid;

  grid-template-columns: 1fr 1fr;
}
.hero__svg {
  height: 30rem;
  /* width: 30rem; */
  background-image: url("../../assets/vacation.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__text h1 {
  font-size: 3.5rem;
  color: #414549;
}
.hero__innerContent {
  font-family: "Inter", sans-serif;
  color: #414549;
  font-size: 2.5rem;
  font-weight: 400;

  margin-top: 2rem;
}
.hero__button {
  display: flex;
  justify-content: start;
}
.hero__button button {
  margin-top: 2rem;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 400px;
  height: 70px;
  border: none;
  background-color: #4395f5;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero__companies {
  margin-top: 8rem;
}
.hero__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hero__description span {
  font-size: 2rem;
  color: #414549;
  font-weight: 500;
}
.hero__companiesLogo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 10rem;
}
.hero__companyItem1 {
  background-image: url("../../assets/amazon.svg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__companyItem2 {
  background-image: url("../../assets/Microsoft.svg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__companyItem3 {
  background-image: url("../../assets/users.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__stats {
  height: 20rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5rem;
}
.hero__statText {
  font-size: 2rem;
  color: #414549;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  /* justify-content: star; */
  align-items: start;
}
.hero__statImage {
  background-image: url("../../assets/GrowthCurve.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  margin-top: 3rem;
}
.hero__faq {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 3rem;
}
.hero__faqtext h1 {
  font-size: 2.5rem;
  font-family: "Inter", sans-serif;
  color: #4395f5;
  font-weight: 400;
}
.hero__faqtext span {
  font-size: 2rem;
  font-family: "Inter", sans-serif;
  color: #414549;
  font-weight: 400;
}
.hero__faqSection {
  margin-top: 3rem;
  margin-left: 1rem;
}
.hero__nextFeature h1 {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #414549;
}
.hero__testinomial h1 {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #414549;
}
.hero__nextFeatureCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.nc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero__testinomialCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 3rem;
  gap: 20px;
}
.hero__plansText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.plansInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
}

.hero__plansText h1 {
  font-size: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #414549;
}
.hero__nextcaller {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 2rem 2rem;
}
.hero__nextcaller h1 {
  font-size: 4rem;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #4395f5;
}
.hero__nextcaller span {
  font-size: 2rem;
  font-family: "Inter", sans-serif;
  color: #414549;
  font-weight: 300;
}

.hero__prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 3rem;
}
.hero__prices h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #414549;
}
.hero__priceCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.highlighted {
  border: 2px solid #ffc727;
  scale: 1.1;
}

@media screen and (max-width: 1263px) {
  .hero__stats {
    grid-template-columns: 1fr;
  }
  .hero__testinomialCards {
    grid-template-columns: 1fr 1fr;
  }
  .hero__faq {
    grid-template-columns: 1fr;
    margin-top: 30rem;
  }
  .hero__nextcaller {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .hero__priceCards {
    display: flex;
    flex-direction: column;
  }
  .pc {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .hero__content {
    grid-template-columns: 1fr;
  }
  .hero__nextFeatureCards {
    grid-template-columns: 1fr;
  }
  .hero__nextFeature h1 {
    font-size: 1.4rem;
    font-weight: 400;
  }
  .hero__text h1 {
    font-size: 3rem;
  }
  .hero__innerContent {
    font-size: 1.6rem;
  }
  .hero__button {
    /* margin-top: 1rem; */
    margin-top: 0.5rem;
  }
  .hero__button button {
    font-size: 1.3rem;
    width: 300px;
    height: 60px;
  }
  .hero__companies {
    margin-top: 3rem;
  }
  .hero__faq {
    margin-top: 25rem;
  }
}
@media screen and (max-width: 700px) {
  .hero__companiesLogo {
    grid-template-columns: 1fr;
    width: 100%;
    height: 30rem;
  }
  .hero__companyItem1 {
    margin-left: 28px;
  }
  .hero__companyItem2 {
    margin-left: 12px;
  }
  .hero__stats {
    grid-template-columns: 1fr;
  }
  .hero__companiesLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 700px) {
  .hero__text h1 {
    font-size: 2.5rem;
  }
  .hero__main {
    padding: 0;
  }
  .hero__innerContent {
    font-size: 1.5;
  }
  .hero__statText {
    font-size: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .hero__testinomialCards {
    grid-template-columns: 1fr;
  }
  .hero__faq {
    margin-top: 30rem;
  }
  .hero__testinomial h1 {
    font-size: 2rem;
  }
  .hero__plansText h1 {
    font-size: 2.5rem;
  }
  .hero__nextcaller h1 {
    font-size: 3rem;
  }
  .hero__svg {
    height: 20rem;
  }
  .hero__prices h1 {
    font-size: 35px;
  }
  .highlighted {
    scale: 1;
  }
}
